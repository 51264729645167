import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['radio_ffs_operation_date', 'ffs_operation_date_gteq', 'ffs_operation_date_lteq']

  onchangeFilterWithFfsOperationDate(event) {
    if(event.target.value === '0') {
      this.setDisabledFfsOperationDate(true)
    }else{
      this.setDisabledFfsOperationDate(false)
    }
  }

  setDisabledFfsOperationDate(disabled) {
    this.ffs_operation_date_gteqTarget.disabled = disabled
    this.ffs_operation_date_lteqTarget.disabled = disabled
  }

}
