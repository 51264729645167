// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import * as $ from 'jquery/src/jquery';
window.$ = $;
require.context('../images', true, /\.(png|jpg|jpeg|svg|ico|gif)$/);

import 'bootstrap';
import 'bootstrap/scss/bootstrap.scss';
import './styles/application.scss';
import './static/js/main.min.js';
import './static/js/custom.js';
import Highcharts from 'highcharts'

window.Highcharts = Highcharts;
window.Rails = Rails;
import 'chartkick/chart.js';
import 'controllers'
Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
//= require chartkick
//= require Chart.bundle
