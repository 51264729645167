import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["form"]

  level = {
    level_1_division: 1,
    level_2_division: 2,
    level_3_division: 3,
    level_4_division: 4,
    level_5_division: 5,
    level_6_division: 6,
    level_7_division: 7,
    level_8_division: 8,
    level_9_division: 9,
    level_10_division: 10
  }
  connect() {
    this.localStorageKey = window.location
    // Retrieve data from localStorage when the Controller loads.
    this.setFormData();
  }

  getFormData() {
    const form = new FormData(this.formTarget);
    let data = []
    for (var pair of form.entries()) {
      data.push([pair[0], pair[1]])
    }
    return Object.fromEntries(data)
  }

  saveToLocalStorage() {
    const data = this.getFormData();
    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  setFormData() {
    if (localStorage.getItem('level_1_division') != null && localStorage.getItem(this.localStorageKey) != null) {
      const data = JSON.parse(localStorage.getItem(this.localStorageKey));
      let leveDivisions = []
      for (const entry of Object.entries(data)) {
        let name = entry[0];
        let value = entry[1];
        if (name == 'level_1_division') {
          continue;
        }
        if (Object.keys(this.level).includes(name)) {
            leveDivisions = gon.divisions.filter((x) => {
              return x.id == value
            });
        }
        if (localStorage.getItem(name) != null) {
          const child_data = JSON.parse(localStorage.getItem(name));

          Object.entries(child_data).forEach((level) => {
            if (level[0] != '' && level[0]) {
              $(`#${name}`).append(
                $('<option>', {
                  text: level[1],
                  value: level[0],
                })
              )
            }
          })
        }
      }
    }
  }

  getParentDivisionId(level) {
    let concatDivisionID = '';
    for (let i = 1; i <= level.length; i++) {
      concatDivisionID += '/' + level[i].value;
    }
    return concatDivisionID.slice(1);
  }
}
