import { Controller } from '@hotwired/stimulus';
import Dendrogram from '../../packs/dendrogram';

export default class extends Controller {
  static targets = ['submitButton', 'replaceDendrogramModal', 'graphSettings'];
  static values = { dendrogram: String, klassname: String, objectid: String, submiturl: String };

  onCallDendrogramApi(e) {
    const leaderId = $('input[name="leader"]:checked').val();
    this.submitButtonTarget.disabled = true;
    if (!leaderId) {
      alert('先頭者が選択されていません。');
      this.submitButtonTarget.disabled = false;
      return;
    }

    const formData = new FormData();
    formData.append('leader_id', leaderId);
    formData.append('dendrogram_type', this.dendrogramValue);
    formData.append('object_id', this.objectidValue);
    formData.append('klass_name', this.klassnameValue);
    this.replaceDendrogramModalTarget.innerHTML = $('#on-loading').html();
    $('#dendrograms-modal').modal('show');

    Rails.ajax({
      url: this.submiturlValue,
      type: 'POST',
      data: formData,
      success: (data) => {
        $('#errors').html('');
        this.replaceDendrogramModalTarget.innerHTML = data.dendrogram;
        const dendrogramGraph = new Dendrogram(data.nodes);
        dendrogramGraph.paint();
        this.submitButtonTarget.disabled = false;
      },
      error: (res) => {
        if (res.errors) {
          $('#errors').html(res.errors);
        } else {
          alert('エラーが発生しました。');
        }
        this.submitButtonTarget.disabled = false;
        setTimeout(() => {
          $('#dendrograms-modal').modal('hide');
        }, 500);
      },
    });
  }
}
