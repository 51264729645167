import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['download'];

  downloadCsv(e) {
    const element = e.target
    $.ajax({
      headers:{ 'Accept': 'text/csv',
      'Content-Type': 'text/csv' },
      url: element.href,
      type: 'POST',
      success: function(e) {
        console.log("success")
      }
    })
  }
}
