import { Controller } from '@hotwired/stimulus';

var type = 'scatter';
export default class extends Controller {
  static targets = [];
  static values = {};

  onShowChartEmployeeScoreDivisions(e) {
    $(".highcharts-scatter-series .highcharts-data-label").hide();
    $("#chart-groups-modal").modal('show');
    Rails.ajax({
      url: e.target.dataset.showurl,
      type: 'GET',
      success: (data) => {
        this.show_chart(data)
      },
    });
    $("#flexSwitchCheckDefault").prop("checked", false);
  };

  onShowChartEmployeeScoreGroup(e) {
    $(".highcharts-scatter-series .highcharts-data-label").hide();
    $("#chart-groups-modal").modal('show');
    Rails.ajax({
      url: e.target.dataset.showurl,
      type: 'GET',
      success: (data) => {
        this.show_chart(data)
      },
    });
    $("#flexSwitchCheckDefault").prop("checked", false);
  };

  onShowSeriesHighchart(e) {
    if (e.currentTarget.checked) {
      $(".highcharts-scatter-series .highcharts-data-label").show();
    } else {
      $(".highcharts-scatter-series .highcharts-data-label").hide();
    }
  }

  show_chart(data) {
    Highcharts.chart('container', {
      chart: {
        type: type,
        margin: [75, 25, 75, 25],
        height: 550,
        marginLeft: 90,
        marginRight: 90,
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      credits: { text: '' },
      legend: {
        enabled: false,
      },
      plotOptions: {
        scatter: {
          showInLegend: false,
          jitter: {
            x: 0.24,
            y: 0,
          },
          marker: {
            radius: 3.5,
            symbol: 'circle',
          },
        },
        series: {
          turboThreshold: 10000,
          dataLabels: {
            enabled: true,
            format: '{point.name}',
            color: '#4a5261',
            allowOverlap: true,
          },
        },
      },
      xAxis: {
        title: {
          text: 'Data',
        },
        labels: {
          enabled: false,
        },
        tickWidth: 0,
        min: -20,
        max: 20,
        tickInterval: 4,
        offset: -200,
        title: { text: null },
        plotLines: [
          {
            color: 'rgba(15, 14, 15, 0.8)',
            dashStyle: 'line',
            width: 1,
            height: 10,
            value: 0,
            zIndex: 3,
          },
        ],
        plotBands: [
          {
            color: '#FFFFFF',
            from: 0,
            to: 2,
            label: {
              text: '脈絡なく動きやすい',
              verticalAlign: 'top',
              align: 'center',
              x: -10,
              y: -20,
              style: {
                fontStyle: 'Noto Sans JP',
                fontSize: 15,
                color: '#4a5261',
              },
            },
          },
          {
            color: '#FFFFFF',
            from: 0,
            to: 2,
            label: {
              text: '慎重で安定的',
              verticalAlign: 'bottom',
              align: 'center',
              x: -10,
              y: 30,
              style: {
                fontStyle: 'Noto Sans JP',
                fontSize: 15,
                color: '#4a5261',
              },
            },
          },
        ],
      },
      yAxis: {
        min: -20,
        max: 20,
        tickInterval: 4,
        offset: -350,
        title: { text: null },
        gridLineWidth: 0,
        labels: {
          enabled: false,
          allowOverlap: true
        },
        plotLines: [
          {
            color: 'rgba(15, 14, 15, 0.8)',
            dashStyle: 'line',
            width: 1,
            value: 0,
            label: {
              rotation: 0,
              y: 15,
              style: {
                fontStyle: 'italic',
              },
            },
            zIndex: 3,
          },
        ],
        plotBands: [
          {
            color: '#FFFFFF',
            from: 0,
            to: 2,
            label: {
              text: 'こだわり',
              align: 'left',
              x: -70,
              y: 15,
              style: {
                fontStyle: 'Noto Sans JP',
                fontSize: 15,
                color: '#4a5261',
              },
            },
          },
          {
            color: '#FFFFFF',
            from: 0,
            to: 2,
            label: {
              text: '面倒見良さ',
              align: 'right',
              x: 90,
              y: 15,
              style: {
                fontStyle: 'Noto Sans JP',
                fontSize: 15,
                color: '#4a5261',
              },
            },
          },
        ],
      },
      series: [
        {
          dashStyle: 'shortDash',
          lineColor: 'rgba(56,56,56,0.5)',
          color: 'rgba( 253, 99, 0, 0.9 )',
          animation: false,
          data: data,
          zones: [{ color: '#D3D3D3' }],
        },
      ],
      tooltip: false,
    });
  }
}
