import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'action']

  onChangeCheckAll() {
    const actionCheckbox = this.actionTarget;
    let currentState     = $(actionCheckbox).is(':checked');

    const allCheckboxes  = this.checkboxTargets;

    allCheckboxes.forEach((checkbox) => {
      $(checkbox).prop('checked', currentState);
    });
  }
}
