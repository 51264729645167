import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['level_1_division', 'level_2_division', 'level_3_division', 'level_4_division', 'level_5_division',
    'level_6_division', 'level_7_division', 'level_8_division', 'level_9_division', 'level_10_division']

  defaultLevel2Divisions  = [{ name: '第二階層', id: '' }];
  defaultLevel3Divisions  = [{ name: '第三階層', id: '' }];
  defaultLevel4Divisions  = [{ name: '第四階層', id: '' }];
  defaultLevel5Divisions  = [{ name: '第五階層', id: '' }];
  defaultLevel6Divisions  = [{ name: '第六階層', id: '' }];
  defaultLevel7Divisions  = [{ name: '第七階層', id: '' }];
  defaultLevel8Divisions  = [{ name: '第八階層', id: '' }];
  defaultLevel9Divisions  = [{ name: '第九階層', id: '' }];
  defaultLevel10Divisions = [{ name: '第十階層', id: '' }];

  divisionElementLevel    = {
    level1DivisionElement:  this.level_1_divisionTarget,
    level2DivisionElement:  this.level_2_divisionTarget,
    level3DivisionElement:  this.level_3_divisionTarget,
    level4DivisionElement:  this.level_4_divisionTarget,
    level5DivisionElement:  this.level_5_divisionTarget,
    level6DivisionElement:  this.level_6_divisionTarget,
    level7DivisionElement:  this.level_7_divisionTarget,
    level8DivisionElement:  this.level_8_divisionTarget,
    level9DivisionElement:  this.level_9_divisionTarget,
    level10DivisionElement: this.level_10_divisionTarget
  }

  onChangeLevel1Division() {
    this.localStorageKey = 'division_id_second'
    const level1DivisionID = this.divisionElementLevel['level1DivisionElement'].value;
    localStorage.setItem('division_id_first', level1DivisionID );
    let level2Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == level1DivisionID;
    });
    this.loopReplaceOptions(2, level2Divisions)
  }

  onChangeLevel2Division() {
    this.localStorageKey = 'division_id_third'
    let level3Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(2);
    });
    this.loopReplaceOptions(3, level3Divisions)
  }

  onChangeLevel3Division() {
    this.localStorageKey = 'division_id_fourth'
    let level4Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(3);
    });
    this.loopReplaceOptions(4, level4Divisions)
  }

  onChangeLevel4Division() {
    this.localStorageKey = 'division_id_fifth'
    let level5Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(4);
    });
    this.loopReplaceOptions(5, level5Divisions)
  }

  onChangeLevel5Division() {
    this.localStorageKey = 'division_id_sixth'
    let level6Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(5);
    });
    this.loopReplaceOptions(6, level6Divisions)
  }

  onChangeLevel6Division() {
    this.localStorageKey = 'division_id_seventh'
    let level7Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(6);
    });
    this.loopReplaceOptions(7, level7Divisions)
  }

  onChangeLevel7Division() {
    this.localStorageKey = 'division_id_eighth'
    let level8Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(7);
    });
    this.loopReplaceOptions(8, level8Divisions);
  }

  onChangeLevel8Division() {
    this.localStorageKey = 'division_id_ninth'
    let level9Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(8);
    });
    this.loopReplaceOptions(9, level9Divisions);
  }

  onChangeLevel9Division() {
    this.localStorageKey = 'division_id_tenth'
    let level10Divisions = gon.divisions.filter((x) => {
      return x.parent_division_id == this.getParentDivisionId(9);
    });
    this.replaceOptions(this.divisionElementLevel[`level10DivisionElement`], this.defaultLevel10Divisions.concat(level10Divisions));
  }

  replaceOptions(elementSelect, options) {
    $(elementSelect).empty();
    this.getFormData(options)
    options.forEach((division) =>
      $(elementSelect).append(
        $('<option>', {
          text: division.name,
          value: division.id,
        })
      )
    );
  }

  getFormData(options) {
    let data = []
    for (var pair of options) {
      data.push([pair.id, pair.name])
    }
    localStorage.setItem(this.localStorageKey, JSON.stringify(Object.fromEntries(data)));
  }

  getParentDivisionId(level) {
    let concatDivisionID = '';
    for (let i = 1; i <= level; i++) {
      concatDivisionID += '/' + this.divisionElementLevel[`level${i}DivisionElement`].value;
    }
    return concatDivisionID.slice(1);
  }

  loopReplaceOptions(current_level, levelDivisions) {
    this.replaceOptions(this.divisionElementLevel[`level${current_level}DivisionElement`], this[`defaultLevel${current_level}Divisions`].concat(levelDivisions));
    for (let i = current_level + 1; i <= Object.keys(this.divisionElementLevel).length; i++) {
      $(this.divisionElementLevel[`level${i}DivisionElement`]).empty();
      $(this.divisionElementLevel[`level${i}DivisionElement`]).append(
        $('<option>', {
          text: this[`defaultLevel${i}Divisions`][0].name,
          value: ''
        })
      )
      let level_division = this.divisionElementLevel[`level${i}DivisionElement`].name
      localStorage.removeItem(level_division);
    }
  }
}
