import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editButton'];
  static values = { url: String };

  onClickEditBtn(e) {
    const editUrl = e.target.dataset.url;
    Rails.ajax({
      url: editUrl,
      type: 'GET',
      success: (data) => {
        this.onOpenEditForm(data);
      },
      error: () => {
        alert('エラーが発生しました。');
      },
    })
  }

  onOpenEditForm(data) {
    $('#edit-modal-wrapper').html(data);
    $('#corporate-position-modal').modal('show');
  }
}
