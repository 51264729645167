import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['formImport'];
  static values = {
    urlValidate: String,
    url: String,
  };

  handleSelectFile() {
    $('#fileDivisionsEmployees').trigger('click');
  }

  submitFile(e) {
    if (e.target.files.length === 0) return;
    let form = 'formImportTarget';
    let url = this.urlValidateValue;
    localStorage.setItem('formTarget', form);
    localStorage.setItem('urlValue', this.urlValue);
    this.sendRequest(form, url);
  }

  sendRequest(form, url) {
    var formData = new FormData(this[form]);
    $('#loader-data').removeClass('hidden');
    Rails.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json',
      data: formData,
      success: function (data) {
        $('.import-data-info-wrapper').html(data);
        $('#import-data-info').addClass('show');
        $('#import-data-info').show();
      },
      complete: function () {
        $('#loader-data').addClass('hidden');
      },
      errors: function (data) {},
    });
  }

  submitData(e) {
    e.currentTarget.classList.add('disabled');
    this.handleProgressSubmit(e);
  }
  cancelSubmit(e) {
    localStorage.clear();
    location.reload();
  }

  handleProgressSubmit(e) {
    e.preventDefault();
    var mydata = new FormData(this[localStorage['formTarget']]);
    var total_data = $('.btn-primary').attr('data-import-total');
    Rails.ajax({
      url: localStorage['urlValue'],
      type: 'POST',
      contentType: 'application/json',
      data: mydata,
      success: function () {
        if (total_data > 500) {
          alert(
            'CSV処理が完了次第メールで完了通知を行います。それまでの間に新しいCSVをアップロードされますと、重複登録が発生するため、ご利用をお控えください。'
          );
        }
        $('#import-data-info').removeClass('show');
        $('#import-data-info').hide();
        localStorage.clear();
        location.reload();
      },
      errors: function () {
        localStorage.clear();
        location.reload();
      },
    });
  }
}
