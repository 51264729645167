import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['valueTotal'];

  onCheckboxMonthClick(e) {
    const currentTotalValue = Number(this.valueTotalTarget.value);
    const currentValue = Number(e.target.dataset.value);
    this.valueTotalTarget.value = e.target.checked
      ? currentTotalValue + currentValue
      : currentTotalValue - currentValue;
  }
}
