import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = [
    'parentDivision',
    'listDivisions',
    'form'
  ];
  static values = { url: String };

  appendChildrenDivision(e) {
    const division = e.currentTarget;
    const divisionId = division.dataset.divisionid;
    const divisionLevel = division.dataset.divisionslevel === undefined ? 1 : parseInt(division.dataset.divisionslevel) + 1;
    const root_division = $('.' + divisionId)

    if (root_division.length === 0 && !division.className.split(' ').includes('active')) {
      Rails.ajax({
        url: `${this.urlValue}/?id=${divisionId}&level=${divisionLevel}`,
        type: 'GET',
        success: (data) => {
          if (data['data'] === ' ') return;
          const el = document.createElement('div');
          el.innerHTML += data['data'];
          division.parentElement.parentElement.append(el);
        },
      });
    }

    if ((root_division.length === 0)) return;

    if (root_division[0].style.display === 'block') {
      root_division.css('display', 'none');
    } else if (root_division[0].style.display === 'none') {
      root_division.css('display', 'block');
    } else {
      alert('エラーが発生しました。');
    }
  }

  submitForm(e) {
    e.preventDefault();
    let divisionsIgnoredIds       = [];
    const form                    = this.formTarget;
    const listDivisions           = this.listDivisionsTarget;
    const listDivisionsNotChecked = listDivisions.querySelectorAll('input[type=checkbox]:not(:checked)');

    listDivisionsNotChecked.forEach((divisions) => {
      divisionsIgnoredIds.push(divisions.value);
    });

    this.appendDivisionsIgnoredIds(divisionsIgnoredIds);
    form.submit();
  };

  appendDivisionsIgnoredIds(divisionsIgnoredIds) {
    const form = this.formTarget;
    const el   = document.createElement('input');
    el.setAttribute('type', 'hidden');
    el.setAttribute('id', 'division_ignored_ids');
    el.setAttribute('name', 'division_ignored_ids');
    el.setAttribute('value', divisionsIgnoredIds.toString());
    form.appendChild(el);
  };
}
