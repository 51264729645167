import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editButton', 'employeeNumber', 'employeeName'];
  static values = { url: String };

  onClickCreateBtn(e) {
    this.onClickEditBtn(e);
  }

  onClickEditBtn(e) {
    Rails.ajax({
      url: e.target.dataset.url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        localStorage.clear();
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        this.onOpenFormModal(data);
      },
      error: () => {
        alert('エラーが発生しました。');
      },
    });
  }

  onOpenFormModal(data) {
    $('#employee-groups-modal-wrapper').html(data);
    $('#employee-groups-modal').modal('show');
  }

  onClickSearchEmployeeButton() {
    const employeeNumber = this.employeeNumberTarget.value;
    const employeeName = this.employeeNameTarget.value;
    const baseUrl = $('input[name="employee_search_url"]').val();
    const employeeIds = $('#employee_groups_base_form_employee_ids').val();
    const formData = new FormData();
    $('.search-group').text('');
    const newContent = $('<i>');
    newContent.addClass('fa fa-spinner fa-spin');
    $('.search-group').append(newContent);
    $('.search-group').prop('disabled', true);
    formData.append('employee_number_eq', employeeNumber);
    formData.append('name_or_name_kana_eq', employeeName);
    formData.append('employee_ids', employeeIds);
    Rails.ajax({
      url: baseUrl,
      type: 'POST',
      dataType: 'JSON',
      data: formData,
      success: function (data) {
        $('.btn-add-employee').attr('disabled', data.filtered_employee_ids.length === 0);
        $('#user-information-wrapper').html(data.employee_infor_html);
        gon.filtered_employee_ids = data.filtered_employee_ids;
      },
      complete: function (data) {
        newContent.remove();
        $('.search-group').prop('disabled', false);
        $('.search-group').text('検 索');
      },
      errors: function () {
        alert('エラーが発生しました。');
      },
    });
  }

  onClickAddEmployeesButton() {
    const employeeIds = gon.filtered_employee_ids;
    if (employeeIds.length === 0) return;
    $('.btn-add-employee').attr('disabled', true);
    const formData = new FormData();
    formData.append('employee_ids', employeeIds);
    const baseUrl = $('input[name="employee_exam_list_url"]').val();
    Rails.ajax({
      url: baseUrl,
      type: 'POST',
      dataType: 'JSON',
      data: formData,
      success: function (data) {
        $('#groups-employees').prepend(data.html_content);
        $('#user-information-wrapper').html('');
        const currentEmployeeIds = $('#employee_groups_base_form_employee_ids').val().split(',');
        const employeeIdsSet = new Set([...employeeIds, ...currentEmployeeIds]);
        const className = employeeIdsSet.size > 0 ? 'd-none' : '';
        $('#employee-empty-list').addClass(className);
        $('#employee_groups_base_form_employee_ids').val(Array.from(employeeIdsSet).join(','));
      },
      errors: function () {
        alert('エラーが発生しました。');
        $('.btn-add-employee').prop('disabled', false);
      },
    });
  }

  submitForm(e) {
    $(e.target).attr('disabled', true);
    const formData = new FormData();
    formData.append('employee_groups_base_form[employee_ids]', $('#employee_groups_base_form_employee_ids').val());
    formData.append('employee_groups_base_form[name]', $('#employee_groups_base_form_name').val());
    formData.append('employee_groups_base_form[comment]', $('#employee_groups_base_form_comment').val());
    Rails.ajax({
      url: $('.employee-form').attr('action'),
      type: $('.employee-form input[name="_method"]').val() || 'POST',
      dataType: 'JSON',
      data: formData,
      success: function (data) {
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            $(`.${name}--error`).text(data.errors[name]);
            $(`.${name}--error`).removeClass('d-none');
          });
          setTimeout(() => {
            $(e.target).attr('disabled', false);
            $('#form-modal').scrollTop(0);
          }, 500);
          return;
        }
        localStorage.clear();
        location.reload();
      },
      errors: function () {
        alert('エラーが発生しました。');
        setTimeout(() => {
          $(e.target).attr('disabled', false);
        }, 500);
      },
    });
  }
}
