import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['replaceModalContent', 'replacePreRelationDiagnoseContent'];
  static values = {};

  onShowDivisionEmployeesModal(e) {
    this.replaceModalContentTarget.innerHTML = $('#on-loading-employees').html();
    $('#division-employees-modal').modal('show');
    Rails.ajax({
      url: e.target.dataset.showurl,
      type: 'GET',
      success: (data) => {
        this.replaceModalContentTarget.innerHTML = data;
      },
      error: (res) => {
        if (res.errors) {
          alert(res.errors);
        } else {
          alert('エラーが発生しました。');
        }
        setTimeout(() => {
          $('#division-employees-modal').modal('hide');
        }, 500);
      },
    });
  }

  onShowPreRelationDiagnoseModal(e) {
    this.replacePreRelationDiagnoseContentTarget.innerHTML = $('#on-loading-employees').html();
    $('#pre-relation-diagnose-modal').modal('show');
    Rails.ajax({
      url: e.target.dataset.showurl,
      type: 'GET',
      success: (data) => {
        this.replacePreRelationDiagnoseContentTarget.innerHTML = data;
      },
      error: (res) => {
        if (res.errors) {
          alert(res.errors);
        } else {
          alert('エラーが発生しました。');
        }
        setTimeout(() => {
          $('#pre-relation-diagnose-modal').modal('hide');
        }, 500);
      },
    });
  }
}
