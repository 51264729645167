import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['id', 'name']
  static values = {employee: String, url: String}

  searchTrainer(){
    let ajaxTimeOut
    let searchUrl = this.urlValue
    let id = this.idTarget.value
    let name = this.nameTarget.value
    let employee = this.employeeValue
    clearTimeout(ajaxTimeOut)
    ajaxTimeOut = setTimeout(function (){
      $.ajax({
        url: searchUrl,
        dataType: "JSON",
        type: "GET",
        data: { employee_number_eq: id, name_or_name_kana_eq: name, employee: employee },
        success: function (data) {
          $('.search-result-content').remove()
          if (data.length != 0) {
            let traineer_1st = true;
            $.each(data, function (key, value) {
              let result_append = document.createElement('div')
              let user_information = document.createElement('div')
              let user_information_id = document.createElement('span')
              let user_information_name = document.createElement('span')
              let user_information_radio_button = document.createElement('input')
              result_append.className = 'search-result-content'
              user_information.className = 'user-information'
              user_information_id.className = 'user-information-id'
              user_information_radio_button.name = 'advice-relationships-traineer'
              user_information_radio_button.type = 'radio'
              user_information_radio_button.idName = `${value.id}`
              user_information_radio_button.value = `${value.id}`
              if (traineer_1st == true) {
                user_information_radio_button.checked = true
              }
              user_information_radio_button.className = 'advice-relationships-traineer-rbtn'
              user_information_id.append(value.employee_number)
              user_information_name.className = 'user-information-name'
              user_information_name.append(value.name)
              user_information.appendChild(user_information_radio_button)
              user_information.appendChild(user_information_id)
              user_information.appendChild(user_information_name)
              result_append.append(user_information)
              $('.staff-add-content').append(result_append)
              traineer_1st = false;
            });
          } else {
            $('.search-result-content').remove()
            var result_append = document.createElement('div')
            var user_information = document.createElement('div')
            var user_information_name = document.createElement('span')
            result_append.className = 'search-result-content'
            user_information.className = 'user-information'
            user_information_name.className = 'user-information-name'
            user_information_name.append('該当の従業員は存在しないか、すでに上司登録済みです')
            user_information.appendChild(user_information_name)
            result_append.append(user_information)
            $('.staff-add-content').append(result_append)
          }
        }
      })}, 500)
  };

  createTrainer(){
    let ajaxTimeOut
    let searchUrl = this.urlValue
    let employee = this.employeeValue
    let radioButtons = document.querySelectorAll('input[name="advice-relationships-traineer"]');
    let selectedTraineerId;
    for (const radioButton of radioButtons) {
      if (radioButton.checked) {
        selectedTraineerId = radioButton.value;
        break;
      }
    }
    clearTimeout(ajaxTimeOut)
    ajaxTimeOut = setTimeout(function (){
      $.ajax({
        url: searchUrl,
        dataType: "JSON",
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        type: "POST",
        data: { employee_id: selectedTraineerId, employee: employee },
        success: function (data) {
          if (data.result == null) return;
          if(data.result == true) {
            location.reload();
          } else {
            $('.search-result-content').remove()
            var result_append = document.createElement('div')
            var user_information = document.createElement('div')
            var user_information_name = document.createElement('span')
            result_append.className = 'search-result-content'
            user_information.className = 'user-information'
            user_information_name.className = 'user-information-name'
            user_information_name.append('エラーが発生しました')
            user_information.appendChild(user_information_name)
            result_append.append(user_information)
            $('.staff-add-content').append(result_append)
          }
        }
      })}, 500)
  };
}
