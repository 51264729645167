$(document).ready(() => {
  $(document).on('click', '.group-user-add-control', function () {
    $('.group-user-add-content').slideToggle('fast');
    $(this).toggleClass('active');
  });

  $(document).on('click', '.group-user-division-add-control', function () {
    $('.group-user-division-add-content').slideToggle('fast');
    $(this).toggleClass('active');
  });

  if ($('.reports').length != 0) {
    $('.sidebar').hide();
  }

  if ($('input[class="advice_relationship_ids[]"]:checked').length == 0) {
    $('#previewPdfButton').attr('disabled','disabled');
    $('#downloadPdfButton').attr('disabled','disabled');
  }

  $('.ms-2.me-4.form-check-input.check').change(function() {
    if($('input[name="advice_relationship_ids[]"]:checked').length > 0) {
      $('#previewPdfButton').removeAttr('disabled');
      $('#downloadPdfButton').removeAttr('disabled');
    } else {
      $('#previewPdfButton').attr('disabled','disabled');
      $('#downloadPdfButton').attr('disabled','disabled');
    }
  });
});
