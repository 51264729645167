import { Controller } from '@hotwired/stimulus';
const defaultOptions = [
  [{ name: '第一階層', id: '' }],
  [{ name: '第二階層', id: '' }],
  [{ name: '第三階層', id: '' }],
  [{ name: '第四階層', id: '' }],
  [{ name: '第五階層', id: '' }],
  [{ name: '第六階層', id: '' }],
  [{ name: '第七階層', id: '' }],
  [{ name: '第八階層', id: '' }],
  [{ name: '第九階層', id: '' }],
  [{ name: '第十階層', id: '' }]
];
const dataTarget = [
  'level_1_division',
  'level_2_division',
  'level_3_division',
  'level_4_division',
  'level_5_division',
  'level_6_division',
  'level_7_division',
  'level_8_division',
  'level_9_division',
  'level_10_division'
];
const notUniqueCode = 409
const dataAction = [
  'change->employees--divisionGroup#onChangeLevel1Division',
  'change->employees--divisionGroup#onChangeLevel2Division',
  'change->employees--divisionGroup#onChangeLevel3Division',
  'change->employees--divisionGroup#onChangeLevel4Division',
  'change->employees--divisionGroup#onChangeLevel5Division',
  'change->employees--divisionGroup#onChangeLevel6Division',
  'change->employees--divisionGroup#onChangeLevel7Division',
  'change->employees--divisionGroup#onChangeLevel8Division',
  'change->employees--divisionGroup#onChangeLevel9Division',
  '',
];
export default class extends Controller {
  static targets = ['saveEmployee', 'deleteDivisions', 'addDivisionGroup', 'listDivisionsGroups', 'selectPosition', 'employeeNumber', 'btnDownload'];
  static values = { url: String };

  addNewDivisionGroup() {
    const newDivisionGroup = this.createBlockDivisionsGroup();
    const buttonAdd = this.addDivisionGroupTarget;
    $(newDivisionGroup).insertBefore($(buttonAdd).parent());
  }


  saveEmployeeInformation() {
    const _this = this;
    const listDivisionIds = this.listDivisionIds(this.listDivisionsGroupsTarget);
    const formData = new FormData();
    formData.append('list_division_ids', listDivisionIds);
    formData.append('corporate_position_id', _this.selectPositionTarget.value);
    formData.append('employee_number', this.employeeNumberTarget.textContent)
    Rails.ajax({
      url: this.urlValue,
      type: 'PATCH',
      dataType: 'JSON',
      data: formData,
      success: function (data) {
        window.location.reload()
      },
      error: function (data) {
        if(data.status == notUniqueCode) {
          const employeeNumberWrapper = this.employeeNumberTarget
          let messageTag = document.createElement('p')
          messageTag.className = 'text-danger mt-3'
          messageTag.innerText = data.message
          employeeNumberWrapper.appendChild(messageTag)
        } else {
          window.location.reload()
        }
      }
    });
  }

  createFlashMessage(message, typeMessage) {
    const _this = this;
    let divFlash = document.createElement('div');
    divFlash.id = 'flash-message';
    divFlash.className = 'alert alert-' + typeMessage;
    divFlash.innerText = message;
    $('#flash-parent').append(divFlash);
    setTimeout(function () {
      _this.clearMessage(divFlash);
    }, 5000);
  }

  clearMessage(divFlash) {
    $('#flash-parent').find(divFlash).remove();
  }

  listDivisionIds(listDivisionsGroups) {
    let listDivisionIds = [];
    $(listDivisionsGroups)
      .children('.divisions-group')
      .each(function () {
        let lastLevel;
        $(this)
          .children('select')
          .each(function () {
            if (this.value) {
              lastLevel = this.value;
            }
          });
        listDivisionIds.push(lastLevel);
      });
    return listDivisionIds;
  }

  createBlockDivisionsGroup() {
    let divBlock = this.createGroupDivisionsBlock();
    for (let i = 0; i < 10; i++) {
      let options;
      if (i == 0) {
        options = defaultOptions[i].concat(gon.roots_divisions);
      } else {
        options = defaultOptions[i];
      }
      let elementSelect = this.createSelectElement(i);
      this.appendListOptionsToSelect(options, elementSelect);
      divBlock.appendChild(elementSelect);
    }
    $(divBlock).append(this.buttonDeleteDivisionsGroup());
    return divBlock;
  }

  buttonDeleteDivisionsGroup() {
    return `<a class='delete-employee-divisions'
        data-employees--popupEmployeeDetails-target='deleteDivisions'
        data-action='click->employees--popupEmployeeDetails#deleteDivisionsGroup'
        >
        <i class='far fa-trash-alt'></i>
      </a>`;
  }

  createSelectElement(index) {
    let elementSelect = document.createElement('select');
    elementSelect.setAttribute('data-employees--divisionGroup-target', dataTarget[index]);
    elementSelect.setAttribute('data-action', dataAction[index]);
    elementSelect.className = 'form-control form-control-lg form-select d-block w-100 mb-3';
    return elementSelect;
  }

  createGroupDivisionsBlock() {
    let divBlock = document.createElement('div');
    divBlock.className = 'divisions-group';
    divBlock.setAttribute('data-controller', 'employees--divisionGroup');
    return divBlock;
  }

  deleteDivisionsGroup(event) {
    let index = this.deleteDivisionsTargets.findIndex(t => t === event.currentTarget);
    $(this.deleteDivisionsTargets[index]).parent().remove();
  }

  closeEmployeeDetailsPopup() {
    $('#employee-details-modal').modal('hide');
  }

  appendListOptionsToSelect(options, elementSelect, current_id) {
    options.forEach((division) =>
      $(elementSelect).append(
        $('<option>', {
          text: division.name,
          value: division.id,
          selected: current_id === division.id,
        })
      )
    );
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  viewReport(e) {
    const employeeId = this.element.dataset.id
    const reportType = e.target.dataset.type
    const url = this.element.dataset.employeeReport + '?employee_id=' +  employeeId + '&report_type=' + reportType
    window.open(url)
  }

  downloadPdf(e) {
    const iconLoading = e.currentTarget.firstChild;
    iconLoading.classList.remove('d-none');
    const btnDownload = this.btnDownloadTargets;
    const employeeId  = this.element.dataset.id
    const reportType  = e.target.dataset.type
    var form = document.createElement("form");
    var element1 = document.createElement("input");
    var element2 = document.createElement("input");
    var element3 = document.createElement("input");
    btnDownload.forEach((element) => element.classList.add('disabled'));

    form.classList.add('d-none');
    element1.classList.add('d-none');
    element2.classList.add('d-none');
    element3.classList.add('d-none');
    form.method = "POST";
    form.action = `${this.element.dataset.downloadPdfPath}?format=pdf`;

    element1.value= employeeId;
    element1.name="employee_id";
    form.appendChild(element1);

    element2.value=reportType;
    element2.name="report_type";
    form.appendChild(element2);

    element3.value= $("meta[name='csrf-token']").attr("content");
    element3.name="authenticity_token";
    form.appendChild(element3);

    document.body.appendChild(form);

    this.checkStatusDownload = setInterval(() => {
      this.getCookie('download_pdf', iconLoading);
    }, 1000);

    form.submit();
    form.remove();
    element1.remove();
    element2.remove();
    element3.remove();
  }

  getCookie(name, iconLoading) {
    const btnDownload = this.btnDownloadTargets;
    var cookieArr = document.cookie.split(";");
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
          if (decodeURIComponent(cookiePair[1]) == 'done') {
            this.delete_cookie(name);
            btnDownload.forEach((element) => element.classList.remove('disabled'));
            clearInterval(this.checkStatusDownload);
            iconLoading.classList.add('d-none');
          }
        }
    }
  }

  delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
