import { Controller } from '@hotwired/stimulus';

const defaultOptions = [
  [{ name: '第一階層', id: '' }],
  [{ name: '第二階層', id: '' }],
  [{ name: '第三階層', id: '' }],
  [{ name: '第四階層', id: '' }],
  [{ name: '第五階層', id: '' }],
  [{ name: '第六階層', id: '' }],
  [{ name: '第七階層', id: '' }],
  [{ name: '第八階層', id: '' }],
  [{ name: '第九階層', id: '' }],
  [{ name: '第十階層', id: '' }]
];
const dataTarget = [
  'level_1_division',
  'level_2_division',
  'level_3_division',
  'level_4_division',
  'level_5_division',
  'level_6_division',
  'level_7_division',
  'level_8_division',
  'level_9_division',
  'level_10_division',
  'employeeDivisions',
];

const dataAction = [
  'change->employees--divisionGroup#onChangeLevel1Division',
  'change->employees--divisionGroup#onChangeLevel2Division',
  'change->employees--divisionGroup#onChangeLevel3Division',
  'change->employees--divisionGroup#onChangeLevel4Division',
  'change->employees--divisionGroup#onChangeLevel5Division',
  'change->employees--divisionGroup#onChangeLevel6Division',
  'change->employees--divisionGroup#onChangeLevel7Division',
  'change->employees--divisionGroup#onChangeLevel8Division',
  'change->employees--divisionGroup#onChangeLevel9Division',
  '',
];
export default class extends Controller {
  static targets = ['button', 'divisions'];
  static values = { id: String, url: String };

  loadEmployeeDetails() {
    const _this = this;
    const currentID = this.idValue;
    const newId = $('#employee-details-modal').data('id');
    if (currentID === newId) {
      _this.openEmployeeDetailsPopup();
    } else {
      Rails.ajax({
        url: this.urlValue,
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
          _this.editEmployeeDetails(data);
          _this.openEmployeeDetailsPopup();
        },
        errors: function (data) {},
      });
    }
  }

  editEmployeeDetails(data) {
    const employee = data.employee;
    const mode = $('#employee-details-modal').data('mode');
    const employeeDivisionPaths = data.employee_division_paths;
    const corporatePositionIdSelected = data.corporate_position_id_selected;
    const employeePopupElement = $('#employee-details-modal');
    const HTMLElementDivisionsGroup = employeePopupElement.find('#divisions')[0];
    const elementCorporatePositionsSelect = employeePopupElement.find('#corporate-positions-select');
    employeePopupElement.attr('data-id', employee.id);
    employeePopupElement.find('#employee-number').text(employee.employee_number);
    employeePopupElement.attr('data-employees--popupEmployeeDetails-url-value', data.update_path);
    employeePopupElement.find('#full-name').text(employee.full_name);
    employeePopupElement.find('#full-name-kana').text(employee.full_name_kana);
    employeePopupElement.find('#user-created-at').text(employee.user_created_at);
    employeePopupElement.find('#ffs-type').text(employee.ffs_type);
    employeePopupElement.find('#ffs-scores').html(this.renderFactorFfsScores(employee));
    employeePopupElement.find('#ffs-operation-date').text(employee.ffs_operation_date);
    employeePopupElement.find('#language').text(employee.language);
    employeePopupElement.find('#stress-test-info').html(this.renderStressTestInfo(employee.stress_test_info));
    employeePopupElement.find('#employee-number p.text-danger').remove()
    this.disabledButtonPdf(employeePopupElement, data.employee.took_the_test)
    if (mode === 'view_only') {
      employeePopupElement.find('#employee-number').text(employee.employee_number);
      employeePopupElement.find('#employee-divisions').html(employee.parent_divisions.join('<hr />'));
      employeePopupElement.find('#employee-position').text(employee.position);
    } else {
      elementCorporatePositionsSelect.val(corporatePositionIdSelected);
      $(HTMLElementDivisionsGroup).empty();
      employeeDivisionPaths.forEach((list_ids) => {
        this.createGroupDivisionsSelect(list_ids, HTMLElementDivisionsGroup);
      });
      $(HTMLElementDivisionsGroup).append(this.buttonAddDivisionGroup());
    }
  }

  disabledButtonPdf(employeePopupElement, disabled) {
    employeePopupElement.find('.user-detail-buttons').children('button').prop('disabled', disabled)
  }

  createGroupDivisionsSelect(list_ids, divisionsGroup) {
    let divBlock = this.createGroupDivisionsBlock();
    for (let i = 0; i < 10; i++) {
      let select = this.createOptionsDivisionByLevel(list_ids[i], list_ids.slice(0, i), i);
      divBlock.appendChild(select);
      divisionsGroup.appendChild(divBlock);
    }
    $(divBlock).append(this.buttonDeleteDivisionsGroup());
  }

  createOptionsDivisionByLevel(current_id, parent_division_ids, index) {
    let firstOptions = defaultOptions[index];
    let elementSelect = this.createSelectElement(index);
    if (index == 0) {
      this.appendListOptionsToSelect(firstOptions.concat(gon.roots_divisions), elementSelect, current_id);
    } else {
      if (parent_division_ids.length == index) {
        let listOptions = gon.divisions.filter((x) => {
          return x.parent_division_id == parent_division_ids.join('/');
        });
        this.appendListOptionsToSelect(firstOptions.concat(listOptions), elementSelect, current_id);
      } else {
        this.appendListOptionsToSelect(firstOptions, elementSelect, current_id);
      }
    }
    return elementSelect;
  }

  appendListOptionsToSelect(options, elementSelect, current_id) {
    options.forEach((division) =>
      $(elementSelect).append(
        $('<option>', {
          text: division.name,
          value: division.id,
          selected: current_id === division.id,
        })
      )
    );
  }

  buttonAddDivisionGroup() {
    return `<div class="block-add-division-group text-center">
        <button class="btn btn-light rounded-pill"
          data-employees--popupEmployeeDetails-target="addDivisionGroup"
          data-action="click->employees--popupEmployeeDetails#addNewDivisionGroup"
        >
          <i class="fas fa-plus me-2"></i>
          部署を追加
        </button>
      </div>`;
  }

  renderFactorFfsScores(employee) {
    return [
      'factor_ffs_score_a',
      'factor_ffs_score_b',
      'factor_ffs_score_c',
      'factor_ffs_score_d',
      'factor_ffs_score_e',
      'factor_ffs_score_stress',
      'factor_ffs_score_reliability',
    ].map((attr) => employee[attr] + '</br>');
  }

  renderStressTestInfo(stressTestInfo) {
    let htmlString = '';
    const employeeOperationInfo = stressTestInfo.employee_operation_info;
    const listStressInformation = stressTestInfo.list_stress_information;

    htmlString += this.renderLineStressInfo(employeeOperationInfo);
    listStressInformation.forEach((stressInfo) => (htmlString += this.renderLineStressInfo(stressInfo)));
    return htmlString;
  }

  renderLineStressInfo(stressInfo) {
    if (!stressInfo.ffs_operation_date) {
      return '';
    }

    return [
      "<span class='no-margin-left stress-test-info'>" + stressInfo.ffs_operation_date + '</span>',
      "<span class='stress-test-info'>" + stressInfo.ffs_score_stress + '</span>',
      "<span class='stress-test-info'>" + stressInfo.language + '</span>',
      '<br/>',
    ].join('');
  }

  buttonDeleteDivisionsGroup() {
    return `<a class="delete-employee-divisions"
        data-employees--popupEmployeeDetails-target="deleteDivisions"
        data-action="click->employees--popupEmployeeDetails#deleteDivisionsGroup"
        >
        <i class="far fa-trash-alt"></i>
      </a>`;
  }

  createSelectElement(index) {
    let elementSelect = document.createElement('select');
    elementSelect.setAttribute('data-employees--divisionGroup-target', dataTarget[index]);
    elementSelect.setAttribute('data-action', dataAction[index]);
    elementSelect.className = 'form-control form-control-lg form-select d-block w-100 mb-3';
    return elementSelect;
  }

  createGroupDivisionsBlock() {
    let divBlock = document.createElement('div');
    divBlock.className = 'divisions-group';
    divBlock.setAttribute('data-controller', 'employees--divisionGroup');
    return divBlock;
  }

  openEmployeeDetailsPopup() {
    $('#employee-details-modal').modal('show');
  }
}
