import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {employee: String}

  openModalTrainerRegister(){
    $("#trainer-id").val('');
    $("#trainer-name").val('');
    $('.search-result-content').remove()
    $("#boss-register .modal-body").attr('data-advice-relationships--trainer-employee-value', this.employeeValue)
    $("#boss-register").modal('show')
  };

  openModalTraineeRegister(){
    $("#trainee-id").val('');
    $("#trainee-name").val('');
    $('.search-result-content').remove()
    $("#staff-register .modal-body").attr('data-advice-relationships--trainee-employee-value', this.employeeValue)
    $("#staff-register").modal('show')
  };
}
