$(function() {

  // Scroll Page Top
  $('.btn-back-to-top').hide();
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 200) {
      $('.btn-back-to-top').fadeIn("fast");
    } else {
      $('.btn-back-to-top').fadeOut("fast");
    }
  });
  $('.btn-back-to-top').click(function () {
    $('body,html').animate({scrollTop: 0}, 100, "swing");
    return false;
  });

  // Scroll Top
  $('a[href^="#"]').click(function(){
    let speed = 100;
    let href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  // Check All
  $("#check-all").click(function () {
    $(".check").prop('checked', $(this).prop('checked'));
  });

  // SlideToggle
  $('.top-information-control').click(function(){
    $('.top-information-list').slideToggle('fast');
    $(this).toggleClass('active');
  });
  $('.group-user-add-control').click(function(){
    $('.group-user-add-content').slideToggle('fast');
    $(this).toggleClass('active');
  });
  $('.department-list-control').click(function(){
    $(this).parent().nextAll('.department-list-child').slideToggle('fast');
    $(this).toggleClass('active');
  });

});

// Tooltip
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
});
