import { Controller } from "@hotwired/stimulus"
const adviceParamsKey = 'advice_relationship_ids'
export default class extends Controller {
  static targets = ['form', 'checkAll', 'btnDownloadTrainer']
  static values = { format: String }

  previewPdf(e) {
    e.preventDefault()
    const form = this.formTarget
    if(!$(form).serialize().includes(adviceParamsKey)){
      $(this.checkAllTarget).trigger('click')
    }
    form.method = 'get'
    $(form).find('input[name=authenticity_token]').remove()
    $(form).find('input[name=format]').remove()
    form.submit()
  }

  showDownload(e) {
    e.preventDefault()
    $("#download-button").modal('show')
  }

  downloadPdf(e) {
    const iconLoading = e.currentTarget.firstChild;
    iconLoading.classList.remove('d-none');
    e.preventDefault()
    const btnDownloadTrainer = this.btnDownloadTrainerTargets;
    const form = this.formTarget
    if(!$(form).serialize().includes(adviceParamsKey)){
      $(this.checkAllTarget).trigger('click')
    }

    btnDownloadTrainer.forEach((element) => element.classList.add('disabled'));
    const authenticate_token = $('meta[name=csrf-token]').attr('content')
    let hiddenAuth = document.createElement('input')
    hiddenAuth.classList.add('d-none')
    hiddenAuth.name = 'authenticity_token'
    hiddenAuth.value = authenticate_token
    form.append(hiddenAuth)
    let formatPdf = document.createElement('input')
    formatPdf.name = 'format'
    formatPdf.value = e.target.dataset.format
    formatPdf.classList.add('d-none');
    form.append(formatPdf)
    form.method = 'post'
    this.checkStatusDownload = setInterval(() => {
      this.getCookie('download_advice_relationships_report', iconLoading);
    }, 1000);
    form.submit();
    hiddenAuth.remove();
    formatPdf.remove();
  }

  getCookie(name,iconLoading) {
    const btnDownloadTrainer = this.btnDownloadTrainerTargets;
    var cookieArr = document.cookie.split(";");
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name == cookiePair[0].trim()) {
          if (decodeURIComponent(cookiePair[1]) == 'done') {
            this.delete_cookie(name);
            btnDownloadTrainer.forEach((element) => element.classList.remove('disabled'));
            clearInterval(this.checkStatusDownload);
            iconLoading.classList.add('d-none');
          }
        }
    }
  }

  delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
