import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [''];
  static values = { url: String };

  onClickAllEmployees() {
    $(".check-all").on("change", (e) => {
      $(".selectable").prop("checked", e.target.checked);
      if (e.target.checked) {
        $('.btn-add-employee-division').attr('disabled', false);
      } else {
        $('.btn-add-employee-division').attr('disabled', true);
      }
    });
  }

  onClickEmployees() {
    $(".selectable").on("change", (e) => {
      if (e.target.checked) {
        $('.btn-add-employee-division').attr('disabled', false);
      } else {
        $('.btn-add-employee-division').attr('disabled', true);
      }
    });
  }

  onClickAddEmployeesButton() {
    this.get_selected_employee();
    const employeeIds = gon.filtered_employee_ids;
    if (employeeIds.length === 0) return;
    $('.btn-add-employee-division').attr('disabled', true);
    const formData = new FormData();
    formData.append('employee_ids', employeeIds);
    const baseUrl = $('input[name="employee_exam_list_url"]').val();
    Rails.ajax({
      url: baseUrl,
      type: 'POST',
      dataType: 'JSON',
      data: formData,
      success: function (data) {
        $("#groups-employees > tr").each(function () {
          var self = $(this);
          var employee_id = self.data('employees--employeedetails-id-value')
          if (data.html_content.includes(`data-employees--employeeDetails-id-value="${employee_id}"`)) {
            self.remove();
          }
        });
        $('#groups-employees').prepend(data.html_content);
        $('#user-information-wrapper').html('');
        const currentEmployeeIds = $('#employee_groups_base_form_employee_ids').val().split(',');
        const employeeIdsSet = new Set([...employeeIds, ...currentEmployeeIds]);
        const className = employeeIdsSet.size > 0 ? 'd-none' : '';
        $('#employee-empty-list').addClass(className);
        $('#employee_groups_base_form_employee_ids').val(Array.from(employeeIdsSet).join(','));
      },
      errors: function () {
        alert('エラーが発生しました。');
        $('.btn-add-employee').prop('disabled', false);
      },
    });
  }

  get_selected_employee() {
    let arr = []
    $(".selectable").each(function () {
      if ($(this).is(":checked")) {
        arr.push($(this).val());
      }
    });
    gon.filtered_employee_ids = arr
  }
}
