import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["form"]

  level = {
    division_id_first:   1,
    division_id_second:  2,
    division_id_third:   3,
    division_id_fourth:  4,
    division_id_fifth:   5,
    division_id_sixth:   6,
    division_id_seventh: 7,
    division_id_eighth:  8,
    division_id_ninth:   9,
    division_id_tenth:  10,
  }
  connect() {
    this.localStorageKey = window.location;
    // Retrieve data from localStorage when the Controller loads.
    this.setFormData();
  }

  getFormData() {
    const form = new FormData(this.formTarget);
    let data = [];
    for (var pair of form.entries()) {
      data.push([pair[0], pair[1]])
    }
    return Object.fromEntries(data);
  }

  saveToLocalStorage() {
    const data = this.getFormData();
    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  setFormData() {
    if (localStorage.getItem('division_id_first') != null && localStorage.getItem(this.localStorageKey) != null) {
      const data = JSON.parse(localStorage.getItem(this.localStorageKey));
      let leveDivisions = [];
      for (const entry of Object.entries(data)) {
        let name = entry[0];
        let value = entry[1];
        if (name == 'division_id_first') {
          continue;
        }
        if (Object.keys(this.level).includes(name)) {
            leveDivisions = gon.divisions.filter((x) => {
              return x.id == value;
            });
        }
        if (localStorage.getItem(name) != null) {
          const child_data = JSON.parse(localStorage.getItem(name));

          Object.entries(child_data).forEach((level) => {
            if (level[0] != '' && level[0]) {
              $(`#${name}`).append(
                $('<option>', {
                  text: level[1],
                  value: level[0],
                })
              )
            }
          })
        }
      }
    }
  }

  getParentDivisionId(level) {
    let concatDivisionID = '';
    for (let i = 1; i <= level.length; i++) {
      concatDivisionID += '/' + level[i].value;
    }
    return concatDivisionID.slice(1);
  }
}
